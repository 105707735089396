




































































































































































































































import { rsvpInvitationService } from '@/services/modules/rsvp/invitation'
import appConfig from '@/app.config.json'
import communique from '@/notification'
import SiteSection from '@/components/SiteSection.vue'
import i18n from '@/setup/i18n'
import { esMx } from '@/utils/language'
import { unformatPhoneNumber } from '@/utils/phone'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import { DrinkViewModel, rsvpDrinkService } from '@/services/modules/rsvp/drink'

export default defineComponent({
  name: 'SiteSectionRsvp',

  components: {
    SiteSection,
    ValidationObserver,
    ValidationProvider,
  },

  setup() {
    const form = ref<HTMLFormElement | null>(null)
    const isSubmitting = ref(false)
    const name = ref('')
    const phone = ref('')
    const email = ref('')
    const drinkList = ref<string[]>([])
    const isAttending = ref('yes')
    const message = ref('')
    const plusOne = ref(false)

    watch(
      () => drinkList.value,
      (newValue) => {
        if (newValue.length > 2) {
          drinkList.value = newValue.slice(-2)
        }
      }
    )

    const nameLabel = computed(
      () => i18n.t('rsvpFields.name.placeholder') as string
    )
    const phoneLabel = computed(
      () => i18n.t('rsvpFields.phone.label') as string
    )
    const emailLabel = computed(
      () => i18n.t('rsvpFields.email.label') as string
    )
    const yesLabel = computed(() => i18n.t('yes') as string)
    const noLabel = computed(() => i18n.t('no') as string)
    const messageLabel = computed(
      () => i18n.t('rsvpFields.message.label') as string
    )
    const optionalLabel = computed(() => i18n.t('optional') as string)
    const plusOneLabel = computed(() => i18n.t('plusOne') as string)
    const plusOneHintLabel = computed(() => i18n.t('plusOneHint') as string)

    async function onSubmit(): Promise<void> {
      try {
        isSubmitting.value = true

        const willAttend = isAttending.value === 'yes'

        await rsvpInvitationService.insert({
          clientId: appConfig.api.clientId,
          clientName: appConfig.title,
          languageId: esMx.guid,
          title: `[RSVP] ${willAttend ? 'Sí' : 'No'} - ${name.value}`,
          toEmail: 'pau.gonzalezc97@gmail.com',
          name: name.value,
          phone: phone.value ? unformatPhoneNumber(phone.value) : '',
          email: email.value,
          guest: plusOne.value ? 2 : 1,
          arriveDate: new Date(),
          isAttending: willAttend,
          mealPreference: '',
          message: message.value,
          drinkList: drinkList.value.map((id) => ({ id })),
        })

        name.value = ''
        phone.value = ''
        email.value = ''
        message.value = ''
        plusOne.value = false
        isAttending.value = 'yes'
        drinkList.value = []

        form.value?.reset()

        communique.dispatch({
          variant: 'success',
          message: i18n.t('rsvpSubmitSuccess') as string,
        })
      } catch (error) {
        communique.dispatch({
          variant: 'error',
          message: i18n.t('rsvpSubmitError') as string,
        })
      } finally {
        isSubmitting.value = false
      }
    }

    const isLoadingDrinkList = ref(false)
    const drinkListOptions = ref<DrinkViewModel[]>([])

    fetchDrinkListOptions()

    async function fetchDrinkListOptions() {
      try {
        isLoadingDrinkList.value = true

        const {
          data: { items },
        } = await rsvpDrinkService.get({
          clientId: appConfig.api.clientId,
          languageId: esMx.guid,
        })

        drinkListOptions.value = items
      } catch (error) {
        // Fail gracefully, do not show the drink option
      } finally {
        isLoadingDrinkList.value = false
      }
    }

    return {
      isSubmitting,
      name,
      phone,
      email,
      drinkList,
      isAttending,
      message,
      plusOne,
      nameLabel,
      phoneLabel,
      emailLabel,
      yesLabel,
      noLabel,
      messageLabel,
      optionalLabel,
      plusOneLabel,
      plusOneHintLabel,
      drinkListOptions,
      onSubmit,
      form,
      bride: appConfig.wedding.bride,
      groom: appConfig.wedding.groom,
    }
  },
})
